import React from 'react';
import '../App.css';
import './Expertisesection.css';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { Button } from './Button';
import ScrollAnimation from 'react-animate-on-scroll';

import websitedesign from './assets/images/websitedesign.gif';
import customwebsitedesigndevelopment from './assets/images/customwebsitedesigndevelopment.gif';
import websitedevelopment from './assets/images/websitedevelopment.gif';
import mobileappdesign from './assets/images/mobileappdesign.gif';
import datainsights from './assets/images/datainsights.gif';
import outsourcing from './assets/images/logodesign.gif';


function Expertisesection() {

  const location = useLocation();

  // will be true/false
  const hidebtn = location.pathname.includes('services');

  let pageText = location.pathname == "/services" ? "We offer a range of design and development services to connect with your target audience. " : "iWebdesign are specialists in"


  return (
    <div className='Areas-of-expertise'>

      <div class="row">
        <div class="col-md-4">

          <ScrollAnimation animateIn="fadeInLeft">
          <h1>{pageText}</h1>
          </ScrollAnimation>
          <br/>

          {!hidebtn &&
          <ScrollAnimation animateIn='flipInX'>
          <Link to="/services">

            <span className="borderBtn"><strong> Services</strong><span className="dot">◉</span> </span>

          </Link>
          </ScrollAnimation>
        }

        </div>


        <div class="col-md-8">
          <ScrollAnimation animateIn="fadeIn">
            <div class="row">
                <div class="col-sm-6">
                  <Link to='/Outsourcing'>
                    <div class="card">
                      <div class="card-body">
                        <img src={outsourcing} alt="iWebDesign Auckland" className="websitedevelopment"/>
                        <h5 class="card-title">Outsourcing</h5>
                        <p class="card-text">There are many strategies that can help your tech business grow rapidly, however, outsourcing is a smarter resourcing strategy that allows you to hire staff for a significantly lower cost than if you were to recruit locally.</p>
                      </div>
                    </div>
                  </Link>
                </div>
                <div class="col-sm-6">
                <Link to='/Websitedesignauckland'>
                      <div class="card">
                        <div class="card-body">
                          <img src={websitedesign} alt="iWebDesign Auckland" className="webdesignicons"/>
                          <h5 class="card-title">Website Design</h5>
                          <p class="card-text">Our creative UX/UI designers are enthusiastic to provide a good web experiences with a clear focus on interactions, creating moments that are engaging, welcoming and keeps upto the latest design trends.</p>
                        </div>
                      </div>
                  </Link>
                </div>
              </div>
            </ScrollAnimation>


            <ScrollAnimation animateIn="fadeIn">
              <div class="row">
                  <div class="col-sm-6">
                  <Link to='/Customwebsitedesigndevelopmentauckland'>
                      <div class="card">
                        <div class="card-body">
                          <img src={websitedevelopment} alt="iWebDesign Auckland" className="websitedevelopment"/>
                          <h5 class="card-title">Custom Website Design & Development</h5>
                          <p class="card-text">We design and develop your website inhouse here in Auckland. Whether you are launching a new website or tweaking your existing site, our team will ensure that your business communicates effectively with your target audience.</p>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div class="col-sm-6">
                  <Link to='/Websitedevelopmentauckland'>
                        <div class="card">
                          <div class="card-body">
                            <img src={customwebsitedesigndevelopment} alt="iWebDesign Auckland" className="webdesignicons"/>
                            <h5 class="card-title">Website Development</h5>
                            <p class="card-text">We develop, from small business websites to large eCommerce online shop web applications. We work carefully with our clients to create websites and applications that work hard, achieve results and generate a return on investment.</p>
                          </div>
                        </div>
                      </Link>
                  </div>
                </div>
            </ScrollAnimation>

            <ScrollAnimation animateIn="fadeIn">
                <div class="row">
                    <div class="col-sm-6">
                    <Link to='/Mobileappsdesignauckland'>
                        <div class="card">
                          <div class="card-body">
                            <img src={mobileappdesign} alt="iWebDesign Auckland" className="webdesignicons"/>
                            <h5 class="card-title">Mobile Apps Design</h5>
                            <p class="card-text">Our creative UX/UI designers can work collaboratively to deliver a wonderful user friendly mobile applications. Our team will work with design and data insights to make informed decisions based on usability, scalability and engagement.</p>
                          </div>
                        </div>
                      </Link>
                    </div>
                    <div class="col-sm-6">
                    <Link to='/Datainsightsandanalyticsauckland'>
                          <div class="card">
                            <div class="card-body">
                              <img src={datainsights} alt="iWebDesign Auckland" className="webdesignicons"/>
                              <h5 class="card-title">Data Insights & Analytics</h5>
                              <p class="card-text">Using data, you can leverage your business in new ways. You can analyse your target audience, get an accurate picture of your company’s operations and it can help your business increase revenue, improve customer satisfaction.</p>
                            </div>
                          </div>
                        </Link>
                    </div>
                  </div>
              </ScrollAnimation>
        </div>
      </div>
    </div>
  );
}

export default Expertisesection
