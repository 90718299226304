import React from 'react';
import './Blog.css';
import { Link } from 'react-router-dom';
import { Button } from './Button';
import ScrollAnimation from 'react-animate-on-scroll';

import websitedesign from './assets/images/websitedesign.gif';
import websitedevelopment from './assets/images/websitedevelopment.gif';
import tips from './assets/images/tips.jpeg';

import redesign from "./assets/blog/Tips_for_a_Successful_Website_Redesign_in_2021.pdf";



function BlogSection() {
  return (

      <div className='BlogSection'>

      <ScrollAnimation animateIn="fadeInBottom">
        <div class="row">
          <hr />
          <br /><br />
          <div class="col-md-5">
          <h1>Knowledge Base. <span className="Newarrows"><i class="fas fa-arrow-right"></i></span></h1>
          <br />
          <p>Check out the latest blog, that will give you some ideas of websites re-designing, development.</p>
          {/*
            <img src={websitedevelopment} alt="iWebDesign Auckland" className="blogicons"/>
          */}
          </div>

          <div class="col-md-7">
              <div class="row">
              <Link to={redesign} target="_blank">
                  <div className="Blog">

                    <h2>Tips for a Successful Website Redesign in 2021 - <span className="caption">[Click to Read]</span>

                    </h2>
                    <img src={tips} alt="iWebDesign Auckland" className="blogimage"/>
                  </div>
                </Link>
              </div>

          </div>
        </div>
        </ScrollAnimation>




      </div>
  );
}

export default BlogSection;
