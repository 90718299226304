import React from 'react';
import '../../App.css';
import ScrollAnimation from 'react-animate-on-scroll';
import Calltoactionsection from '../Calltoactionsection';
import Footer from '../Footer';
import { Link } from 'react-router-dom';

import servicewebsitedesign from '../assets/images/portfolio/servicewebsitedesign.jpeg';
import sketchtool from '../assets/images/portfolio/sketch-tool.png';
import process from '../assets/images/portfolio/Process.jpeg';
import app from '../assets/images/app.png';

export default function Mobileappsdesignauckland() {
  return (
    <>
    <div className='iWebdesign-services'>
          <ScrollAnimation animateIn="fadeIn">
            <div class="row">
              <div class="col-md-12">
                <span>Services</span>
                <h1>Mobile Apps Design.</h1>
                <hr/>
              </div>
            </div>
            <br /> <br />
            <div class="row">
              <div class="col-md-7">
              <ScrollAnimation animateIn="fadeInLeft">
              <img src={app} alt="iWebDesign Auckland" className="animateImage"/>
              </ScrollAnimation>
              </div>
              <div class="col-md-5">
                <h1>Mobile app design for businesses and start-ups.</h1><br/>
                <p>
                  These days anyone can create an app, but only we craft the kinds of user experiences that leverage emotions and psychology to create truly meaningful ones.
                </p>

                <p>
                  Our creative UX/UI designers are enthusiastic to provide a good application experiences with a clear focus on interactions, creating moments that are engaging, welcoming and keeps upto the latest design trends.

                </p>
                <p>We will create an interactive prototype of your app, which appear and function as similar as possible to the actual product. </p>
                <br/>
                <ScrollAnimation animateIn='flipInX'>
                <Link to="/letstalk">

                    <span className="borderBtn"><strong> Let's Talk </strong>  <span className="dot">◉</span></span>

                </Link>
                </ScrollAnimation>
              </div>
            </div>
          </ScrollAnimation>
    </div>



        <Calltoactionsection />
        <Footer />
    </>
  );
}
