import React from 'react';
import '../App.css';
import './Calltoactionsection.css';
import image from './assets/images/calltoaction.png';
import { Button } from './Button';
import ScrollAnimation from 'react-animate-on-scroll';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';

function Calltoactionsection() {

const location = useLocation();

// Hide CTA button on Letstalk page.
const hidebtn = location.pathname.includes('letstalk');

// Show down arrow only on Letstalk page.
const showArrow = location.pathname.includes('letstalk');

// Show different text on Letstalk page.
let pageText = location.pathname == "/letstalk" ? "Let's have a chat." : "We design & develop responsive websites."

  return (
    <div className='calltoaction'>
    <ScrollAnimation animateIn="fadeInBottom">
        <div class="row">
          <div class="col-md-6">

              <h6>{pageText}</h6>

              {showArrow &&
                <div className="downArrowtalk">
                  <span className="ScrollDownText">Scroll down.</span> <br /><br />
                  <span className="Downarrows"><i class="fas fa-arrow-down"></i></span>
                </div>
              }

              <br/>
              {!hidebtn &&
              <Link to='/letstalk' className='btn-mobile'>
                <Button
                  className='btns'
                  buttonStyle='btn--secondary'
                  buttonSize='btn--large'
                >
                  Start Your Project  <i class="fas fa-pencil-ruler"></i>
                </Button>
              </Link>
              }
          </div>
          <div class="col-md-6">
              <div className="parallaxCalltoAction">
                <img src={image} alt="iWebDesign Auckland" className="image1"/>
              </div>
          </div>
        </div>
      </ScrollAnimation>
    </div>
  );
}

export default Calltoactionsection
