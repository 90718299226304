import React from 'react';
import '../App.css';
import './Introduction.css';
import { Button } from './Button';
import image from './assets/images/iwebdesignauckland.png';
import ScrollAnimation from 'react-animate-on-scroll';
import { Link } from 'react-router-dom';

function IntroductionSection() {
  return (
    <div className='intro-container'>

          <div class="row">
            <div class="col-md-6">
            <ScrollAnimation animateIn="fadeIn">
            <h1>Specialised in web solution for digital projects that require a fresh approach.</h1><br/>
            <p>
              We deliver websites to businesses who expect results. In an increasingly knowledge-led economy, our team is our greatest asset. We value and invest in their expertise and have decades of experience at a senior level.
            </p>
            <p>
              By collaborating with ambitious businesses that recognise the difference between good and excellent, we focus on delivering value through critical thinking, creativity and innovation.
            </p>
            </ScrollAnimation>
            <ScrollAnimation animateIn="fadeInBottom">
            <p>
            </p>
              </ScrollAnimation>
            </div>

            <div class="col-md-6">
            <ScrollAnimation animateIn="fadeInRight">
              <img src={image} alt="iWebDesign Auckland" className="image1"/>
            </ScrollAnimation>
            </div>
          </div>

    </div>
  );
}

export default IntroductionSection
