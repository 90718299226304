import React from 'react';
import CardItem from './CardItem';
import './Cards.css';

function Cards() {
  return (
    <div className='cards'>
    <h1>Areas of expertise</h1>
      <div className='cards__container'>
        <div className='cards__Wrapper'>
          <ul className='cards__items'>
            <CardItem
              src="images/img-9.gif"
              text="Website Design"
              label='What we do'
              path='/services'
            />
            <CardItem
              src="images/img-9.gif"
              text="Custom Website Design & Development"
              label='What we do'
              path='/services'
            />
          </ul>
          <ul className='cards__items'>

          <CardItem
            src="images/img-9.gif"
            text="Website Development"
            label='What we do'
            path='/services'
          />
          <CardItem
            src="images/img-9.gif"
            text="Mobile Apps Design"
            label='What we do'
            path='/services'
          />

            <CardItem
              src="images/img-9.gif"
              text="Data Insights and Analytics"
              label='What we do'
              path='/services'
            />

          </ul>
        </div>
      </div>
    </div>
  );
}

export default Cards;
