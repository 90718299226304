import React from 'react';
import '../../App.css';
import BlogSection from '../Blog';
import Calltoactionsection from '../Calltoactionsection';
import Footer from '../Footer';


export default function Knowledgebase() {
  return (
    <>
    <BlogSection />
    <Calltoactionsection />
    <Footer />
    </>
  );
}
