import React from 'react';
import '../App.css';
import './Servicesection.css';
import image from './assets/images/iwebdesignresponsive.gif';
import ScrollAnimation from 'react-animate-on-scroll';
import { Link } from 'react-router-dom';
import { Button } from './Button';

function Servicesection() {
  return (
    <div className='Building-your-success-online'>

        <div class="row">
          <div class="col-md-6">
          <ScrollAnimation animateIn="fadeIn">
              <div className="parallax"></div>
          </ScrollAnimation>
          </div>
          <div class="col-md-6">
            <ScrollAnimation animateIn="fadeIn">
              <h1>Outsource your projects locally in Auckland.</h1>
            </ScrollAnimation>

            <ScrollAnimation animateIn="fadeInBottom">
              <p>
                Are you currently busy with your own projects and would like to outsource your work locally in Auckland? Well, you have come to the right place.
              </p>
            </ScrollAnimation>

            <ScrollAnimation animateIn="fadeInBottom">
              <p>
                There are many strategies that can help your tech business grow rapidly, however, outsourcing is a smarter resourcing strategy that allows you to hire staff for a significantly lower cost than if you were to recruit locally.
              </p>
            </ScrollAnimation>

            <ScrollAnimation animateIn="fadeInBottom">
              <p>
                We have number of UX/UI designers and web developers who are excellent in designing websites, mobile applications, and website development from small business branding website to E-commerce online store.
              </p>
            </ScrollAnimation>

            <ScrollAnimation animateIn="fadeInBottom">
            <p>
              Our specialists UX/UI Designers, Web Developers, Data Engineers right here in Auckland, New Zealand.
            </p>
            </ScrollAnimation>
            <br/><br/>
            <ScrollAnimation animateIn='flipInX'>
            <Link to="/letstalk">

                <span className="borderBtn"><strong> Let's Talk </strong>  <span className="dot">◉</span></span>

            </Link>
            </ScrollAnimation>

          </div>
        </div>

    </div>
  );
}

export default Servicesection
