import React from 'react';
import '../../App.css';
import ScrollAnimation from 'react-animate-on-scroll';
import Calltoactionsection from '../Calltoactionsection';
import Footer from '../Footer';

import servicewebsitedesign from '../assets/images/portfolio/servicewebsitedesign.jpeg';
import sketchtool from '../assets/images/portfolio/sketch-tool.png';
import process from '../assets/images/portfolio/Process.jpeg';

export default function Websitedesignauckland() {
  return (
    <>
    <div className='iWebdesign-services'>
          <ScrollAnimation animateIn="fadeIn">
            <div class="row">
              <div class="col-md-12">
                <span>Services</span>
                <h1>Website Design.</h1>

                <hr/>
              </div>
            </div>
            <br /> <br />
            <div class="row">
              <div class="col-md-7">
                <ScrollAnimation animateIn="fadeInLeft">
                  <img src={servicewebsitedesign} alt="iWebDesign Auckland" className="animateImage"/>
                </ScrollAnimation>
              </div>
              <div class="col-md-5">
                <ScrollAnimation animateIn="fadeInBottom">
                <h1>A beautiful application that keep your users engaged.</h1><br/>
                <p>
                In iWebDesign understand that a great application is not just about looks (although this certainly helps), website application design needs to take into consideration other factors such as usability, the user interface and journey, goals and objectives, target markets and localisation.</p>

                <p>We like to work with people who really appreciate good design. Our designs will make you stand out from the competition and provide your users with an engaging and refreshing experience. We ensure our code is as lightweight as possible whilst not holding back our creative team on the front end design (UI).</p>
                </ScrollAnimation>
              </div>
            </div>
          </ScrollAnimation>


          <ScrollAnimation animateIn="fadeInBottom">
            <br /> <br /> <br /> <br />
            <div class="row">
              <div class="col-md-12">
                <h1>Our Process.</h1>
                <hr/>
              </div>
            </div>
            <br /> <br />

            <div class="row">
              <div class="col-md-5">
              <ScrollAnimation animateIn="fadeInBottom">
              <h1>The key to a successful website design is planning.</h1><br/>
              <p>Before we start any project we listen to the goals you are trying to achieve and gain an understanding of your business so that we can visualise it within the design process.</p>
              <p>Once we have enough information we develop a sitemap which outlines all the different sections and pages within your website. It also helps to illustrate the website flow and call to action triggers.</p>
              <p>We then look to create detailed wireframes that show the structure of all main pages within the sitemap. The wireframes act as a guide for our web design team, who use them to make sure all elements are transferred over into the designs.</p>
              <p>Once the wireframes are approved we create static designs for the entire website using Sketch or Figma.</p>
              </ScrollAnimation>
              </div>
              <div class="col-md-7">
                <ScrollAnimation animateIn="fadeInRight">
                <img src={sketchtool} alt="iWebDesign Auckland" className="animateImage"/>
                </ScrollAnimation>
              </div>
            </div>
      </ScrollAnimation>

        <ScrollAnimation animateIn="fadeIn">
        <br /> <br /> <br /> <br />
        <div class="row">
          <div class="col-md-12">

            <h1>Design Review.</h1>
            <hr/>
          </div>
        </div>
        <br /> <br />

          <div class="row">
            <div class="col-md-7">
              <ScrollAnimation animateIn="fadeInLeft">
                <img src={process} alt="iWebDesign Auckland" className="animateImage"/>
              </ScrollAnimation>
            </div>
            <div class="col-md-5">
              <ScrollAnimation animateIn="fadeInBottom">
              <h1>In iWebDesign we use inVision digital tool to present the design visuals.</h1>
              <p>Which allows clients to log in, view and comment on wireframes, mood boards, brand assets and web designs as and when they are uploaded. It is a tool that allows us to communicate with our clients with regards to designs, gathering feedback and moving projects forward.</p>
              <p>As you add feedback our design team are automatically notified. Once updates have been posted you will receive a notification and can review the changes and mark comments as resolved or add additional feedback.</p>
              </ScrollAnimation>
            </div>
          </div>
        </ScrollAnimation>
    </div>



        <Calltoactionsection />
        <Footer />
    </>
  );
}
