import React from 'react';
import '../../App.css';
import Expertisesection from '../Expertisesection';
import Calltoactionsection from '../Calltoactionsection';
import Footer from '../Footer';

export default function Services() {
  return (
    <>

        <Expertisesection />
        <Calltoactionsection />
        <Footer />
    </>
  )
}
