import emailjs from "emailjs-com";
import ContactSection from './ContactSection.css';
import React from 'react';
import { Link, useHistory } from "react-router-dom";
import ScrollAnimation from 'react-animate-on-scroll';

export default function Getfreequoteform() {

const history = useHistory();

    function sendEmail(e) {
        e.preventDefault();

    emailjs.sendForm('service_6p0tdoi', 'template_f64dtti', e.target, 'user_IXEPcA0deW3Tf9dlq0Gx5')
        .then((result) => {
            console.log(result.text);
        }, (error) => {
            console.log(error.text);
        });
        e.target.reset();
        history.push('/Success');

    }
    return(

                <div className="contactFormFreeQuote">

                <div class="row">
                    <div class="col-md-12">
                      <ScrollAnimation animateIn="fadeIn">
                          <h1>Get Free Quote</h1>
                          <p>Complete the brief below to help us understand your vision for a new website. We'll get in touch with a personalized quote within 48hours.</p>
                          <p>If you have any questions? Give us a call 02102751272</p>
                          <p>Estimated time to complete: 10 - 20 minutes.</p>
                          <p><span className="star">*</span> Required fields.</p>
                      </ScrollAnimation>

                <div className="freeQuoteSection">
                    <form onSubmit={sendEmail}>

                    <ScrollAnimation animateIn="fadeIn">
                      <div className="categoryDetails">
                        Type of Service
                      </div>
                      <div class="row">
                        <div class="col-md-12">
                            <span>Select services: <span className="star">*</span></span>
                              <div className="details">
                                 <input type="radio" value="Website design" name="service" /> Website design <br/>
                                 <input type="radio" value="Website development" name="service" /> Website development <br/>
                                 <input type="radio" value="Custom Website Design & Development" name="service" /> Custom Website Design & Development <br/>
                                 <input type="radio" value="Re-design / develop your current website" name="service" /> Re-design / develop your current website <br/>
                                 <input type="radio" value="Mobile Apps Design" name="service" /> Mobile Apps Design <br/>
                                 <input type="radio" value="Data Insights & Analytics" name="service" /> Data Insights & Analytics <br/>
                                 <input type="radio" value="Outsouring" name="service" /> Outsouring
                               </div>
                          </div>
                      </div>
                      </ScrollAnimation>

                      <ScrollAnimation animateIn="fadeIn">
                        <div class="categoryDetails">
                          Your contact details
                        </div>
                        <div class="row">
                          <div class="col-md-6">
                              <span>Your first name <span className="star">*</span></span>
                              <div className="details">
                                <input type="text" className="form-control" placeholder="First name" name="fname" required />
                              </div>
                          </div>
                          <div class="col-md-6">
                            <span>Your last name <span className="star">*</span></span>
                              <div className="details">
                                <input type="text" className="form-control" placeholder="last name" name="lname" required />
                              </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6">
                              <span>Your email address <span className="star">*</span></span>
                              <div className="details">
                                <input type="email" className="form-control" placeholder="sales@iwebdesign.co.nz" name="email" required />
                              </div>
                          </div>
                          <div class="col-md-6">
                            <span>Your phone number <span className="star">*</span></span>
                              <div className="details">
                                <input type="text" className="form-control" placeholder="0210275765" name="phone" required />
                              </div>
                          </div>
                        </div>
                        </ScrollAnimation>

                        <ScrollAnimation animateIn="fadeIn">
                        <div class="categoryDetails">
                          Company Details
                        </div>

                        <div class="row">
                          <div class="col-md-6">
                              <span>Company name <span className="star">*</span></span>
                              <div className="details">
                                  <input type="text" className="form-control" placeholder="iWebdesign" name="company" required />
                              </div>
                          </div>
                          <div class="col-md-6">
                            <span>Existing website url</span>
                              <div className="details">
                                <input type="text" className="form-control" placeholder="www.iwebdesign.co.nz" name="website" />
                              </div>
                          </div>
                        </div>
                        </ScrollAnimation>

                        <ScrollAnimation animateIn="fadeIn">
                        <div class="categoryDetails">
                          Website look and feel
                        </div>
                        <div class="row">
                          <div class="col-md-12">
                            <span>Describe the style of your new website? <span className="star">*</span> </span>
                            <div className="details">
                               <input type="radio" value="Business & Service" name="describe" /> Business & Service <br/>
                               <input type="radio" value="Beauty & Hair" name="describe" /> Beauty & Hair <br/>
                               <input type="radio" value="Health & Wellness" name="describe" /> Health & Wellness <br/>
                               <input type="radio" value="Fashion & Style" name="describe" /> Fashion & Style <br/>
                               <input type="radio" value="Travel & Tourism" name="describe" /> Travel & Tourism <br/>
                               <input type="radio" value="Restaurants & Food" name="describe" /> Restaurants & Food <br/>
                               <input type="radio" value="Landing Pages" name="describe" /> Landing Pages <br/>
                               <input type="radio" value="Other" name="describe" /> Other

                             </div>
                          </div>
                        </div>

                        <div class="row">
                          <div class="col-md-12">
                            <span>Do you have graphics, illustrations or video content for the website? <span className="star">*</span> </span>
                            <div className="details">
                               <input type="radio" value="Yes, I will provide images and video content" name="content" /> Yes, I will provide images and video content <br/>
                               <input type="radio" value="No, Please use free stock images" name="content" /> No, Please use free stock images <br/>
                               <input type="radio" value="No, I need unique, custom images or photos." name="content" /> No, I need unique, custom images or photos. <br/>
                               <input type="radio" value="Other" name="content" /> Other
                             </div>
                          </div>
                        </div>

                        <div class="row">
                          <div class="col-md-12">
                            <span>List other website or assets we should draw inspiration from: <span className="star">*</span> </span>
                            <div className="details">
                              <textarea className="form-control" id="" cols="30" rows="7" placeholder="Provide example of other websites with similar feel or layout to the outcome that you’re expecting. (Please list the aspects you like, and why you like them.)" name="inspiration" required></textarea>
                            </div>
                          </div>
                        </div>

                        <div class="row">
                          <div class="col-md-12">
                            <span>Are you re-designing an existing website? <span className="star">*</span> </span>
                            <div className="details">
                               <input type="radio" value="Yes" name="redesigning" /> Yes<br/>
                               <input type="radio" value="No" name="redesigning" /> No
                             </div>
                          </div>
                        </div>

                        </ScrollAnimation>

                        <ScrollAnimation animateIn="fadeIn">
                        <div class="categoryDetails">
                          Re-designing Details (Skip, if you are not re-designing your current website)
                        </div>

                        <div class="row">
                          <div class="col-md-12">
                            <span>What is the main reason behind the redesign?</span>
                            <div className="details">
                              <textarea className="form-control" id="" cols="30" rows="7" placeholder="Tell us what you want to achieve." name="reason" required></textarea>
                            </div>
                          </div>
                        </div>
                          </ScrollAnimation>

                          <ScrollAnimation animateIn="fadeIn">
                        <div class="categoryDetails">
                          Website Budget
                        </div>

                        <div class="row">
                          <div class="col-md-12">
                            <span>What is your expected budget for this project? <span className="star">*</span> </span><br/>
                            <small>We only ask so that we can manage your expectations professionally.</small>
                            <div className="details">
                               <input type="radio" value="Under $500" name="pricerange" /> Under $500 <br/>
                               <input type="radio" value="$500 - $1,000" name="pricerange" /> $500 - $1,000 <br/>
                               <input type="radio" value="$1,000 - $2,000" name="pricerange" /> $1,000 - $2,000<br/>
                               <input type="radio" value="$2,000 - $5,000" name="pricerange" /> $2,000 - $5,000<br/>
                               <input type="radio" value="$5,000 - $10,000" name="pricerange" /> $5,000 - $10,000<br/>
                               <input type="radio" value="$10,000 - $20,000" name="pricerange" /> $10,000 - $20,000<br/>
                               <input type="radio" value="$20,000 - $30,000" name="pricerange" /> $20,000 - $30,000<br/>
                               <input type="radio" value="$35,000+" name="pricerange" /> $35,000+
                             </div>
                          </div>
                        </div>
                          </ScrollAnimation>

                        <ScrollAnimation animateIn="fadeIn">
                        <div class="categoryDetails">
                          Pages & Additional Info
                        </div>

                        <div class="row">
                          <div class="col-md-12">
                            <span>What pages do you have on your website? & Any other information you would like to let us know?</span>
                            <div className="details">
                              <textarea className="form-control" id="" cols="30" rows="7" placeholder="Home, Our Story, Services, Testimonials, Contact Us, etc." name="pages" required></textarea>
                            </div>
                          </div>
                        </div>
                        </ScrollAnimation>

                        <ScrollAnimation animateIn="fadeIn">
                          <div class="row">
                            <div class="col-md-8">
                              <h2>Thank you! Press submit to send us your brief.</h2>
                              <p>We'll get in touch with a personalised quote within 48hours.</p>
                            </div>
                            <div class="col-md-4"><br/><br/>
                                <input type="submit" onClick="" className="SubmitBtn" value="Send Message"></input>
                            </div>
                          </div>
                          </ScrollAnimation>
                        </form>
                      </div>
                </div>
            </div>
        </div>
    )
};
