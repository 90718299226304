import React from 'react';
import './App.css';
import Navbar from './components/Navbar';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';

import Home from './components/pages/Home';
import Ourstory from './components/pages/Ourstory';

import Services from './components/pages/Services';
import Websitedesignauckland from './components/pages/Websitedesignauckland';
import Customwebsitedesigndevelopmentauckland from './components/pages/Customwebsitedesigndevelopmentauckland';
import Websitedevelopmentauckland from './components/pages/Websitedevelopmentauckland';
import Mobileappsdesignauckland from './components/pages/Mobileappsdesignauckland';
import Datainsightsandanalyticsauckland from './components/pages/Datainsightsandanalyticsauckland';
import Outsourcing from './components/pages/Outsourcing';

import Portfolio from './components/pages/Portfolio';
import Knowledgebase from './components/pages/Knowledgebase';

import Letstalk from './components/pages/Letstalk';
import Getfreequote from './components/pages/Getfreequote';
import Success from './components/pages/Success';

import Termsandconditions from './components/pages/Termsandconditions';

import PageNotFound from './components/pages/pagenotfound';

import ScrollToTop from './components/ScrollToTop';


function App() {
  return (
    <>

    <Router>

      <Navbar />

        <Switch>
          <Route path='/' exact component={Home} />
          <Route path='/ourstory' component={Ourstory} />
          <Route path='/services' component={Services} />
          <Route path='/portfolio' component={Portfolio} />
          <Route path='/knowledgebase' component={Knowledgebase} />
          <Route path='/letstalk' component={Letstalk} />

          <Route path='/Websitedesignauckland' component={Websitedesignauckland} />
          <Route path='/Customwebsitedesigndevelopmentauckland' component={Customwebsitedesigndevelopmentauckland} />
          <Route path='/Websitedevelopmentauckland' component={Websitedevelopmentauckland} />
          <Route path='/Mobileappsdesignauckland' component={Mobileappsdesignauckland} />
          <Route path='/Datainsightsandanalyticsauckland' component={Datainsightsandanalyticsauckland} />
          <Route path='/Outsourcing' component={Outsourcing} />

          <Route path='/Getfreequote' component={Getfreequote} />
          <Route path='/Termsandconditions' component={Termsandconditions} />
          <Route path='/Success' component={Success} />

          <Route component={PageNotFound} />


        </Switch>
        <ScrollToTop />
    </Router>

    </>
  );
}

export default App;
