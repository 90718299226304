import emailjs from "emailjs-com";
import ContactSection from './ContactSection.css';
import React from 'react';
import { Link, useHistory } from "react-router-dom";

export default function ContactUs() {

const history = useHistory();

    function sendEmail(e) {
        e.preventDefault();

    emailjs.sendForm('service_6p0tdoi', 'template_kqlzyni', e.target, 'user_IXEPcA0deW3Tf9dlq0Gx5')
        .then((result) => {
            console.log(result.text);
        }, (error) => {
            console.log(error.text);
        });
        e.target.reset();
        history.push('/Success');

    }
    return(
          <div class="row">
              <div class="col-md-12">
                <div className="contactFormBG">
                  <form onSubmit={sendEmail}>
                          <div class="col-md-6">
                            <span>Your full name *</span>
                              <div className="details">
                                <input type="text" className="form-control" placeholder="First and last name" name="name" required />
                              </div>

                              <span>Company name *</span>
                              <div className="details">
                                <input type="text" className="form-control" placeholder="iWebdesign" name="company" required />
                              </div>

                              <span>Your email address *</span>
                              <div className="details">
                              <input type="email" className="form-control" placeholder="sales@iwebdesign.co.nz" name="email" required />
                              </div>

                              <span>Your phone number *</span>
                              <div className="details">
                                  <input type="text" className="form-control" placeholder="0210275765" name="phone" required />
                              </div>

                              <span>Existing website</span>

                              <div className="details">
                                  <input type="text" className="form-control" placeholder="www.iwebdesign.co.nz" name="website" />
                              </div>
                            </div>

                            <div class="col-md-6">
                              <span>Services Type? *</span>
                              <div className="details">
                                <select id="serviceType" name="service" className="form-control" required>
                								  <option name="serviceType" id="Select Service" for="Select Service" value="Select Service">Select Service</option>
                                  <option name="serviceType" id="Outsourcing" for="Outsourcing" value="Outsourcing">Outsourcing</option>
                								  <option name="serviceType" id="Website Design" for="Website Design" value="Website Design">Website Design</option>
                								  <option name="serviceType" id="Website Development" for="Website Development" value="Website Development">Website Development</option>
                                  <option name="serviceType" id="Mobile App Design" for="Mobile App Design" value="Mobile App Design">Mobile App Design</option>
                                  <option name="serviceType" id="Data Insights and Analytics" for="Data Insights and Analytics" value="Data Insights and Analytics">Data Insights and Analytics</option>
                								</select>
                              </div>

                              <span>What's your estimated budget range? *</span>
                              <div className="details">
                                <select id="budget" name="service" className="form-control" required>
                                  <option name="budget" id="Select" for="Select" value="">Select Range $</option>
                                  <option name="budget" id="$500-$1000" for="$500-$1000" value="$500-$1000">$500-$1000</option>
                                  <option name="budget" id="$1000-$1500" for="$1000-$1500" value="$1000-$1500">$1000-$1500</option>
                                  <option name="budget" id="$1500-$3000" for="$1500-$3000" value="$1500-$3000">$1500-$3000</option>
                                  <option name="budget" id="More than $3000" for="More than $3000" value="More than $3000">More than $3000</option>
                                  <option name="budget" id="More than $5000" for="More than $5000" value="More than $5000">More than $5000</option>
                                </select>
                              </div>

                              <span>Message *</span>
                              <div className="details">
                                <textarea className="form-control" id="" cols="30" rows="7" placeholder="Your message" name="message" required></textarea>
                              </div>

                              <input type="submit" onClick="" className="SubmitBtn" value="Send Message"></input>
                          </div>
                      </form>
                </div>
            </div>
        </div>
    )
};
