import React from 'react';
import '../../App.css';
import ContactSection from '../ContactSection';
import Calltoactionsection from '../Calltoactionsection';
import Cards from '../Cards';
import Footer from '../Footer';


function Letstalk() {
  return (
    <>
      <Calltoactionsection />
      <ContactSection />
      <Footer />
    </>
  );
}
export default Letstalk;
