import React from 'react';
import '../../App.css';
import HeroSection from '../HeroSection';
import Introduction from '../Introduction';
import Servicesection from '../Servicesection';
import Expertisesection from '../Expertisesection';
import Calltoactionsection from '../Calltoactionsection';
import PortfolioSection from '../PortfolioSection';
import Cards from '../Cards';
import Footer from '../Footer';


function Home() {
  return (
    <>
      <HeroSection />
      <Introduction />
      <Servicesection />
      <Expertisesection />
      <Calltoactionsection />
      <PortfolioSection />
      <Footer />
    </>
  );
}

export default Home;
