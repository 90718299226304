import React from 'react';
import '../../App.css';
import ScrollAnimation from 'react-animate-on-scroll';
import Calltoactionsection from '../Calltoactionsection';
import Footer from '../Footer';
import { Link } from 'react-router-dom';

import websitedevelopment from '../assets/images/websitedevelopment01.jpeg';
import wordpress from '../assets/images/wordpress.png';
import oncord from '../assets/images/oncord.png';
import bootstrap from '../assets/images/bootstrap.jpeg';
import duda from '../assets/images/duda.png';
import coding from '../assets/images/coding.gif';
import shopify from '../assets/images/shopify.png';
import squarespace from '../assets/images/squarespace.png';




export default function Websitedevelopmentauckland() {
  return (
    <>
    <div className='iWebdesign-services'>
          <ScrollAnimation animateIn="fadeIn">
            <div class="row">
              <div class="col-md-12">
                <span>Services</span>
                <h1>Website Development.</h1>
                <hr/>
              </div>
            </div>
            <br /> <br />
            <div class="row">
              <div class="col-md-6">
              <ScrollAnimation animateIn="fadeInLeft">
                <img src={coding} alt="iWebDesign Auckland"/>
              </ScrollAnimation>
              </div>
              <div class="col-md-6">
              <ScrollAnimation animateIn="fadeInBottom">
                <h1>We specialise in creating fully mobile responsive and highly converting websites.</h1><br/>

                <p>We have got an experienced developers in our team, who are passionate about creating high performance websites that transform our clients into market leaders. We build websites using the very best open source platforms, award winning CMS which keeps your business secure. We will work closely with our clients to learn about their targeted audience and the goals clients wanted to achieve. </p>

                <p>
                  Do you want to know costs of your website?
                  Please fill request a quote form and we will get back to you within 48 hours.
                </p>
              </ScrollAnimation>
                <br/>
                <ScrollAnimation animateIn='flipInX'>
                <Link to="/Getfreequote">

                    <span className="borderBtn"><strong> Request a Free Quote </strong>  <span className="dot">◉</span></span>

                </Link>
                </ScrollAnimation>
              </div>
            </div>
          </ScrollAnimation>


          <ScrollAnimation animateIn="fadeInBottom">
            <br /> <br /> <br /> <br />
            <div class="row">
              <div class="col-md-12">

                <h1>Our Platform.</h1>
                <hr/>
              </div>
            </div>
            <br /> <br />

            <div className='CMS'>

              <div class="row">

                <div class="col-md-4">
                <ScrollAnimation animateIn="fadeInLeft">
                <h1>We use an award-winning content management system (CMS).</h1>
                <p>Which enables you to build web sites and powerful online applications. Many aspects, including its ease-of-use and extensibility.
                </p>
                </ScrollAnimation>
                </div>


                <div class="col-md-8">

                <ScrollAnimation animateIn="fadeIn">
                    <div class="row">
                        <div class="col-sm-6">
                              <div class="card">
                                <div class="card-body">
                                  <img src={squarespace} alt="iWebDesign Auckland" className="cms-icons"/>
                                  <h5 class="card-title">Squarespace</h5>
                                  <p class="card-text">Squarespace creates a modern platform that enables millions to build a brand, share their stories, and transact with their customers in an impactful and beautiful online presence. Squarespace also do have a better blogging tool and better support than most competitors.</p>
                                </div>
                              </div>
                        </div>

                        <div class="col-sm-6">
                            <div class="card">
                              <div class="card-body">
                                <img src={shopify} alt="iWebDesign Auckland" className="cms-icons"/>
                                <h5 class="card-title">Shopify</h5>
                                <p class="card-text"> Shopify is completely cloud-based and hosted, which means you don't have to worry about upgrading or maintaining software or web servers. This gives you the flexibility to access and run your business from anywhere with an internet connection.</p>
                              </div>
                            </div>
                        </div>
                      </div>
                  </ScrollAnimation>

                  <ScrollAnimation animateIn="fadeIn">
                    <div class="row">
                        <div class="col-sm-6">
                              <div class="card">
                                <div class="card-body">
                                  <img src={wordpress} alt="iWebDesign Auckland" className="cms-icons"/>
                                  <h5 class="card-title">Wordpress</h5>
                                  <p class="card-text">WordPress CMS is basically a tool that makes it easy to manage important aspects of your website – like content – without needing to know anything about programming. It is also known as the most popular way to create an eCommerce store as well!</p>
                                </div>
                              </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="card">
                              <div class="card-body">
                                <img src={oncord} alt="iWebDesign Auckland" className="cms-icons"/>
                                <h5 class="card-title">Oncord (New)</h5>
                                <p class="card-text">Oncord features a completely re-imagined page editing experience that provides more control over your website. Easily drag-and-drop content and layout elements onto pages, or embed media elements like images, video, audio, documents or PDF files.</p>
                              </div>
                            </div>
                        </div>
                      </div>
                    </ScrollAnimation>

                    <ScrollAnimation animateIn="fadeIn">
                        <div class="row">
                            <div class="col-sm-6">
                                <div class="card">
                                  <div class="card-body">
                                    <img src={duda} alt="iWebDesign Auckland" className="cms-icons"/>
                                    <h5 class="card-title">Duda</h5>
                                    <p class="card-text">Duda's Website Builder provides businesses with a one-stop website solution that works on multiple screen sizes. The benefits of responsive design with the low cost of Duda products.</p>
                                  </div>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                  <div class="card">
                                    <div class="card-body">
                                      <img src={bootstrap} alt="iWebDesign Auckland" className="cms-icons"/>
                                      <h5 class="card-title">Bootstrap</h5>
                                      <p class="card-text">Bootstrap is the most popular CSS Framework for developing responsive and mobile-first websites. Bootstrap has become an essential a tool for our developers.</p>
                                    </div>
                                  </div>
                            </div>
                          </div>


                      </ScrollAnimation>


                </div>
              </div>
            </div>
      </ScrollAnimation>
    </div>



        <Calltoactionsection />
        <Footer />
    </>
  );
}
