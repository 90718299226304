import React from 'react';
import '../../App.css';
import ScrollAnimation from 'react-animate-on-scroll';
import Calltoactionsection from '../Calltoactionsection';
import Footer from '../Footer';

export default function Termsandconditions() {
  return (
    <>
        <div className='success'>
                <div class="row">
                  <div class="col-md-12">
                  <ScrollAnimation animateIn="fadeIn">
                  <h1>Success.</h1>
                    <span>Thank you for getting in touch with iWebDesign NZ, we've received your enquiry.</span><br/><br/>
                    <span>We aim to respond to all enquiries within 48 hours so you'll be hearing from us shortly. </span><br/><br/>
                    <span>We look forward to speaking with you soon!</span><br/><br/>
                    <span>Return to <a href="/">Home</a>.</span>
                    </ScrollAnimation>
                  </div>
                </div>
        </div>
        <Footer />
    </>
  );
}
