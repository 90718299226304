import React from 'react';
import '../App.css';
import './OurStoryPage.css';
import { Button } from './Button';
import image from './assets/images/iwebdesignauckland.png';
import ourstory from './assets/images/ourstory.png';
import ScrollAnimation from 'react-animate-on-scroll';
import { Link } from 'react-router-dom';

function OurStoryPage() {
  return (
    <div className='storyintro-container'>
    <ScrollAnimation animateIn='fadeIn'>
      <div class="row">
        <div class="col-md-6">
        <h1>Where it all begin.</h1>
        <br/>
        <p>In 2017, as a graduate student from AUT, I observed a trend among digital agencies in Auckland. They were outsourcing all their web development projects internationally. Motivated by this insight, I recognized the opportunity to locally design and develop projects, aiming to assist small businesses in Auckland in acquiring customized websites at reasonable prices.</p>
        <br/>
        <p>
        This vision materialized into the creation of iWebDesign, a company dedicated to aiding digital agencies, software firms, and small enterprises in establishing a robust online presence without incurring high costs. Today, a bunch of small businesses like restaurants and retail stores around Auckland live on the Internet, thanks to iWebDesign!
        </p>
        <br/><br/>
        <ScrollAnimation animateIn='flipInX'>
          <Link to='/Getfreequote' className='btn-mobile'>
          <Button
            className='btns'
            buttonStyle='btn--secondary'
            buttonSize='btn--large'
          >
              Request Free Quote  <i class="fas fa-pencil-ruler"></i>
            </Button>
          </Link>
          </ScrollAnimation>
        </div>

        <div class="col-md-6">
          <img src={ourstory} alt="iWebDesign Auckland" className="image1"/>
        </div>
      </div>
      </ScrollAnimation>
    </div>
  );
}

export default OurStoryPage
