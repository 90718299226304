import React from 'react';
import '../../App.css';
import Getfreequoteform from '../Getfreequoteform';
import Cards from '../Cards';
import Footer from '../Footer';


function Getfreequote() {
  return (
    <>
      <Getfreequoteform />    
      <Footer />
    </>
  );
}
export default Getfreequote;
