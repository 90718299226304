import React from 'react';
import '../../App.css';
import ScrollAnimation from 'react-animate-on-scroll';
import Calltoactionsection from '../Calltoactionsection';
import Footer from '../Footer';
import { Link } from 'react-router-dom';

import servicewebsitedesign from '../assets/images/portfolio/servicewebsitedesign.jpeg';
import sketchtool from '../assets/images/portfolio/sketch-tool.png';
import process from '../assets/images/portfolio/Process.jpeg';
import outsourcing from '../assets/images/outsourcing-page.gif';
import freelancer from '../assets/images/Freelancer.png';
import upwork from '../assets/images/Upwork.png';


export default function Outsourcing() {
  return (
    <>
    <div className='iWebdesign-services'>
          <ScrollAnimation animateIn="fadeIn">
            <div class="row">
              <div class="col-md-12">
                <span>Services</span>
                <h1>Outsouring.</h1>
                <hr/>
              </div>
            </div>
            <br /> <br />
            <div class="row">
              <div class="col-md-6">
                <img src={outsourcing} alt="iWebDesign Auckland" className="outsourcinglogo"/>
              </div>
              <div class="col-md-6">
                <h1>Outsource your projects locally in Auckland.</h1>
                <p>Are you currently busy with your own projects and would like to outsource your work locally in Auckland? Well, you have come to the right place.</p>
                <p>
                  There are many strategies that can help your tech business grow rapidly, however, outsourcing is a smarter resourcing strategy that allows you to hire staff for a significantly lower cost than if you were to recruit locally.
                </p>
                <p>
                  We have number of UX/UI designers and web developers who are excellent in designing websites, mobile applications, and website development from small business branding website to E-commerce online store.
                </p>
                <p>Our specialists UX/UI Designers, Web Developers, Data Engineers right here in Auckland, New Zealand.</p>
                <br/><br/>
                <ScrollAnimation animateIn='flipInX'>
                <Link to="/letstalk">

                    <span className="borderBtn"><strong> Let's Talk </strong>  <span className="dot">◉</span></span>

                </Link>
                </ScrollAnimation>
              </div>
            </div>
          </ScrollAnimation>
    </div>
        <Calltoactionsection />
        <Footer />
    </>
  );
}
