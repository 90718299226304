import React from 'react';
import '../App.css';
import './PortfolioSection.css';
import image from './assets/images/iwebdesignresponsive.gif';
import { Link } from 'react-router-dom';
import { Button } from './Button';
import ScrollAnimation from 'react-animate-on-scroll';

import websitedesign from './assets/images/websitedesign.gif';
import customwebsitedesigndevelopment from './assets/images/customwebsitedesigndevelopment.gif';
import websitedevelopment from './assets/images/websitedevelopment.gif';
import mobileappdesign from './assets/images/mobileappdesign.gif';
import datainsights from './assets/images/datainsights.gif';
import logodesign from './assets/images/logodesign.gif';

function PortfolioSection() {
  return (
    <div className='PortfolioSection'>
    <ScrollAnimation animateIn="fadeInBottom">
      <div class="row">
        <hr />
        <br /><br />
        <div class="col-md-5">
        <h1>A sampling of our clients and recent work.</h1>
        <br />
        <p>We are proud to be part of the growth story for our clients and to share these samples of our work.</p>
        <br />
        <Link to="/portfolio">
          <Button
            className='btns'
            buttonStyle='btn--secondary'
            buttonSize='btn--large'
          >
            Portfolio  <i class="fas fa-book-open"></i>
          </Button>
        </Link>
        </div>

        <div class="col-md-7">
            <div class="row">
            <a href="https://smartkidseducare.co.nz/" title="Visit Smart Kids Educare Website" target="_blank">
              <div className="Projects">
                <h2><span>1.0</span> Smart Kids Educare <span className="caption">Website</span> <span className="arrows"><i class="fas fa-arrow-right"></i></span></h2>
              </div>
            </a>
            <a href="https://www.spotoncleaners.co.nz/" title="Visit Spot-On Cleaners Website" target="_blank">
              <div className="Projects">
                <h2><span>2.0</span> Spot-On Cleaners <span className="caption">Website</span> <span className="arrows"><i class="fas fa-arrow-right"></i></span></h2>
              </div>
            </a>
            <a href="https://www.mmgcommunications.co.nz/" title="Visit MMG Communications Website" target="_blank">
              <div className="Projects">
                <h2><span>3.0</span> MMG Communications <span className="caption">Website</span> <span className="arrows"><i class="fas fa-arrow-right"></i></span></h2>
              </div>
            </a>
            <a href="https://www.itforyou.co.nz/" title="Visit ItForYou Website" target="_blank">
              <div className="Projects">
                <h2><span>4.0</span> IT For You <span className="caption">Website</span> <span className="arrows"><i class="fas fa-arrow-right"></i></span></h2>
              </div>
            </a>
            <a href="https://www.luxelectrical.co.nz/" title="Visit Lux Electrical Website" target="_blank">
              <div className="Projects">
                <h2><span>5.0</span> Lux Electrical <span className="caption">Website</span> <span className="arrows"><i class="fas fa-arrow-right"></i></span></h2>
              </div>
            </a>
            <a href="https://www.senthilmukusha.co.nz/" title="Visit Senthil Mukusha Website" target="_blank">
              <div className="Projects">
                <h2><span>6.0</span> Senthil Mukusha <span className="caption">Website</span> <span className="arrows"><i class="fas fa-arrow-right"></i></span></h2>
              </div>
            </a>
            </div>

        </div>
      </div>
      </ScrollAnimation>
    </div>

  );
}

export default PortfolioSection
