import React from 'react';
import '../../App.css';
import PortfolioPage from '../PortfolioPage';
import PortfolioCard from '../PortfolioCard';
import Calltoactionsection from '../Calltoactionsection';
import Footer from '../Footer';


export default function Portfolio() {
  return (
    <>
        <PortfolioPage />
      
        <Calltoactionsection />
        <Footer />
    </>
  )
}
