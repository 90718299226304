import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import { Link } from 'react-router-dom';
import Footer from '../Footer';


function PageNotFound(){
  return (
    <div className="errorPage">

    <ScrollAnimation animateIn="fadeInTop">
      <div class="row">
        <div class="col-md-12">
        <center>
          <h1>404</h1>
          <h2>Page Not Found</h2>
          <br/>
          <ScrollAnimation animateIn='flipInX'>
          <Link to="/">

              <div className="ReturnHome">
                [ Return home ]
              </div>

          </Link>
          </ScrollAnimation>
        </center>
        </div>
      </div>

    </ScrollAnimation>
        <Footer />

    </div>

  )

}

export default PageNotFound
