import React from 'react';
import '../App.css';
import { Button } from './Button';
import './HeroSection.css';
import ScrollAnimation from 'react-animate-on-scroll';
import { Link } from 'react-router-dom';

function HeroSection() {
  return (
    <div className='hero-container'>
      <video src='/videos/webdesign.mp4' autoPlay loop muted />

        <h1 className="cssanimation sequence fadeInBottom">
        <ScrollAnimation animateIn='flipInX'>
          We create beautiful websites for startups & leading brands in Auckland.
        </ScrollAnimation>
        </h1>
        {/* Creative, flexible and affordable website design studio. */ }
        <p className="cssanimation sequence fadeInBottom">Get your business online with a small budget.</p>
        <div className='hero-btns cssanimation sequence fadeInBottom'>
        <ScrollAnimation animateIn='flipInX'>
          <Link to='/Getfreequote' className='btn-mobile'>
            <Button
              className='btns'
              buttonStyle='btn--outline'
              buttonSize='btn--large'
            >
              Request Free Quote  <i class="fas fa-pencil-ruler"></i>
            </Button>
          </Link>
          </ScrollAnimation>
        </div>

    </div>
  );
}

export default HeroSection
