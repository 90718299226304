import React, { useState, useEffect } from 'react';
import './Footer.css';
import { Button } from './Button';
import { Link } from 'react-router-dom';
import ScrollAnimation from 'react-animate-on-scroll';

import GooglePartners from './assets/images/GooglePartners.png';
import GoDaddyPartners from './assets/images/Godaddy.png';

function Footer() {

  let year = new Date().getFullYear();

  return (

    <div className='footer'>

      <div className="inner-footer">
      <ScrollAnimation animateIn='flipInX'>
        <div class="row">
            <div class="col-md-4">
              <p class="large">Find us on Social.</p>
              <p><a href="https://www.instagram.com/iwebdesign_nz/" target="_blank" title="Instagram - iWebdesign Auckland"><i class="fab fa-instagram"></i> Instagram</a></p>

              <p><img src={GooglePartners} alt="Google iWebDesign Partner" className="partners"/></p>
              <p><img src={GoDaddyPartners} alt="GoDaddy iWebDesign Partner" className="partners"/></p>
            </div>

            <div class="col-md-4">
              <p class="large">Services.</p>
              <p><Link to='/Outsourcing'>Outsourcing</Link></p>
              <p><Link to='/Websitedesignauckland'>Website Design</Link></p>
              <p><Link to='/Websitedevelopmentauckland'>Website Development</Link></p>
              <p><Link to='/Customwebsitedesigndevelopmentauckland'>Custom Website Design & Development</Link></p>
              <p><Link to='/Mobileappsdesignauckland'>Mobile Apps Design</Link></p>
              <p><Link to='/Datainsightsandanalyticsauckland'>Data Insights & Analytics</Link></p>
            </div>

            <div class="col-md-4">
              <p class="large">Let's Talk.</p>
                <p><a href="mailto:contactiwebdesign@gmail.com" title="Send us a email"><i class="fas fa-envelope"></i> contactiwebdesign@gmail.com</a></p>
                <p><a href="tel:+642102751272" title="Call us" ><i class="fas fa-phone"></i> 02102751272</a></p>
                <br />
                <p><Link to='/letstalk'>Contact us</Link></p>
                <p><Link to='/Getfreequote'>Request Free Quote</Link></p>
                <p><Link to='/Termsandconditions'>Terms and Conditions</Link></p>

                <div className="copyright">


                  ©2017 - {year} <a href="http://iwebdesign.co.nz/" target="_blank" title="iWebDesign Auckland, Celebrating 5 years in this industry.">
                    iWebDesign
                  </a> | Creative, flexible and affordable website design studio.
                </div>
            </div>
        </div>
        </ScrollAnimation>
      </div>

        <p className="caption">The future of your business begins here.</p>

    </div>
  );
}


export default Footer;
