import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';
import { Button } from './Button';



function Navbar() {
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  useEffect(() => {
    showButton();
  }, []);

  window.addEventListener('resize', showButton);

  return(
    <>
      <nav className="navbar">
        <div className="navbar-container">
          <Link to="/" onClick={closeMobileMenu} title="iWebDesign Auckland">
            <span className="companyname">i</span><span className="web">Web</span><span className="design">Design.</span>
          </Link>
          {/*<Link to="/" className="navbar-logo" onClick={closeMobileMenu} title="iWebDesign Auckland">

          </Link>*/}
          <div className='menu-icon' onClick={handleClick}>
            <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
          </div>
          <ul className={click ? 'nav-menu active' : 'nav-menu'}>

            <li className='nav-item'>
              <Link to='/ourstory' className='nav-links' onClick={closeMobileMenu}>
                Our Story
              </Link>
            </li>

            <li className='nav-item'>
              <Link to='/services' className='nav-links' onClick={closeMobileMenu}>
                Services
              </Link>
            </li>

            <li className='nav-item'>
              <Link to='/portfolio' className='nav-links' onClick={closeMobileMenu}>
                Portfolio
              </Link>
            </li>

            <li className='nav-item'>
              <Link to='/knowledgebase' className='nav-links' onClick={closeMobileMenu}>
                Knowledge Base
              </Link>
            </li>


            <li className='nav-item'>
              <Link to='/Letstalk' className='nav-links-mobile' onClick={closeMobileMenu} title="Let's talk">
                Let's talk ◉
              </Link>
            </li>
          </ul>
          <Link to='/letstalk' className='btn-mobile'>
          {button && <Button buttonStyle='btn--outline' title="Let's talk">Let's talk <span className="dot">◉</span> </Button>}
          </Link>
        </div>

      </nav>
    </>
  );
}

export default Navbar
