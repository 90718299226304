import React from 'react';
import '../../App.css';
import ScrollAnimation from 'react-animate-on-scroll';
import Calltoactionsection from '../Calltoactionsection';
import Footer from '../Footer';

import servicewebsitedesign from '../assets/images/portfolio/servicewebsitedesign.jpeg';
import sketchtool from '../assets/images/portfolio/sketch-tool.png';
import process from '../assets/images/portfolio/Process.jpeg';

export default function Termsandconditions() {
  return (
    <>
    <div className='iWebdesign-services'>

          <ScrollAnimation animateIn="fadeIn">
            <div class="row">
              <div class="col-md-12">
              <h1>Terms and Conditions.</h1>
              <span>In order to become a client of iWebDesign, you must agree to the following terms and conditions.</span>
              <hr/>
              <br />
                  <h3>1.0 PURPOSE OF THIS AGREEMENT</h3>
                  <p>This agreement sets out the terms that apply to the relationship between you and us. </p>
                  <p>You agree to provide us with and allow us to use all the information necessary to give effect to this agreement and the provision of our products and performance of our services. </p>
                  <p>We will agree to the Privacy Act 1993. We won't utilize your information or data unless we have sensibly ensured it is accurate, complete, relevant and not misleading. If we give your information or data to another substance we will do everything sensibly within our energy to prevent unauthorised use or disclosure of your information or data. You may get to your information or data and request that we redress any oversights.</p>

                  <br />
                  <h3>2.0 OUR SERVICES</h3>

                  <p>Website Design</p>
                  <p>Website Development</p>
                  <p>Ecommerce or Online Shop Website</p>
                  <p>Data Insights and Analytics</p>
                  <p>Mobile App Design</p>
                  <p>Logo Design</p>
                  <p>Re-desiging your current website</p>
                  <p>Website Maintenance - Update content or Add a new feature or support</p>

              </div>
            </div>

            <br /> <br />
            <div class="row">
              <div class="col-md-12">
                <h3>3.0 COST OR PRICE</h3>

                <p>The price is the cost of the products and services as concurred between you and us every once in a while subject to GST and out of pocket costs. In the project that no cost is expressed, the cost will be the standard sum at which that we provide the products and services at the time of the completion of the project. The price is subject to reasonable change due to variations to the products and services to be provided or circumstances beyond our control. The price will be discussed between you and us by selecting the packages.</p>
              </div>
            </div>

            <br /> <br />
            <div class="row">
              <div class="col-md-12">
                <h3>4.0 HOW AND WHEN DO YOU PAY US</h3>

                <p>We will be giving you 3 options.</p>
                <p>Option 1 (50%): You agree to pay us 50% of the fee before we start working on your project.</p>
                <p>Option 2 (40%): You agree to pay us in 40% of the fee before we start provide you the website login details for content loading, etc.</p>
                <p>Option 3 (10%): You agree to pay us the final 10% of the fee + "if any additional cost required to pay" before publishing your project.</p>
                <p>A bank deposit may be required.</p>
                <p>You agree to us allocating or reallocating any payment received from you towards any invoice. If no allocation is made then it is deemed to be in such a way that preserves the maximum value of our purchase money security interest in the products.</p>
                <p>You will be responsible for payment if a third party that you expect to pay you or us fails to pay.</p>
              </div>
            </div>

            <br /> <br />
            <div class="row">
              <div class="col-md-12">
                <h3>5.0 WORK CANCELLED</h3>
                <p>If we begin work on a job and subsequently the customer cancels the order, we reserve the right to invoice for the work carried out and any expenses incurred. If the customer does not progress a job for more than 30 days (Approximately 1 month) (e.g. does not respond to a visual design proposal or proof), we reserve the right to invoice for the work carried out and any expenses incurred.</p>
              </div>
            </div>

            <br /> <br />
            <div class="row">
              <div class="col-md-12">
                <h3>6.0 INTERCHANGES</h3>

                <p>Email exchange offers significantly more storage space, greater stability, improved deliverability and easy integration.</p>
                <p>A maximum of three sets of proofing and design alterations are included in our estimates. Further progressions of proofing and design alterations may incur extra charges.</p>
                <p>Minor changes to content are included in our estimates. Lengthy or significant alterations to content will be charged at the hourly rate plus materials and/or expenses.</p>
                <p>Changes to other material (e.g. photos) and requirements (e.g. website functionality) which significantly affect work already carried out will be charged at the hourly rate plus materials and/or expenses.</p>
            </div>
            </div>

            <br /> <br />
            <div class="row">
              <div class="col-md-12">
                <h3>7.0 EDITING OR PROOFREADING</h3>
                <p>It is the client's obligation to check evidences and site forms for errors in copy and content. Regardless of the possibility that content is provided electronically (i.e. on disk or via e-mail) it is still the client's obligation to check all content on the verification or site rendition for mistakes that may have happened therefore. In the event that any content on the verification is not clear (e.g. through poor fax propagation) it is the client's obligation to to obtain a clear copy from us.</p>
              </div>
            </div>

            <br /> <br />
            <div class="row">
              <div class="col-md-12">
                <h3>8.0 WEBSITE HOSTING</h3>
                  <p>iWebDesign attempts to guarantee that the sites we have remain on the web 24x7. We will react immediately to any reported issue and make all reasonable efforts to restore service. All the web hosting services must be purchased yearly and paid by the client.</p>
                  <p>Want us to back up your website? (Additional Feature) which will be charged as hourly rate of $100 NZD.</p>
            </div>
            </div>

            <br /> <br />
            <div class="row">
              <div class="col-md-12">
                <h3>9.0 COPYRIGHT</h3>
                <p>It is the client's duty to guarantee that copyright is not encroached using any materials they supply to us.</p>
                <p>Images purchased or custom-made by oneclicinfo for the customer are subject to the terms and conditions of the supplier, a copy of which can be supplied on request.</p>
                <p>Copyright on completed design work produced by oneclicinfo transfers to the client once payment of our invoice has been made in full, subject to the conditions above. However, we reserve the right to use material it has designed or produced in our own publicity (e.g. our portfolio), whether in hard copy or electronic form.</p>
                <p>Copyright on any website code, programming or software from third parties remains with the original owner/author unless otherwise agreed. </p>
                <p>Copyright on code produced by oneclicinfo remains with us, but the client is granted a permanent license to use and modify the code for their own use, once payment has been made in full and provided that our copyright notice is preserved within the code.</p>
                <p>We will never sell your personal information to any other company.</p>
              </div>
            </div>


  </ScrollAnimation>
    </div>

        <Footer />
    </>
  );
}
