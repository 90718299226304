import React from 'react';
import '../../App.css';
import ScrollAnimation from 'react-animate-on-scroll';
import Calltoactionsection from '../Calltoactionsection';
import Footer from '../Footer';
import { Link } from 'react-router-dom';

import datainsights from '../assets/images/data-insights-analytics.jpeg';
import data from '../assets/images/data.gif';
import data2 from '../assets/images/data2.gif';

export default function Datainsightsandanalyticsauckland() {
  return (
    <>
    <div className='iWebdesign-services'>
          <ScrollAnimation animateIn="fadeIn">
            <div class="row">
              <div class="col-md-12">
                <span>Services</span>
                <h1>Data Insights & Analytics.</h1>
                <hr/>
              </div>
            </div>
            <br /> <br />
            <div class="row">
              <div class="col-md-7">
                <ScrollAnimation animateIn="fadeInLeft">
                <img src={data} alt="iWebDesign Auckland" className="animateImage"/>
                </ScrollAnimation>
              </div>
              <div class="col-md-5">
                <ScrollAnimation animateIn="fadeInBottom">
                <h1>Does your business make use of its data?</h1><br/>
                <p>
                  Today, data is the driving force of most businesses. Most companies use a number of different software for their everyday operations and these systems collect and save a lot of data. This data can be analysed and converted into useful information which can help leverage your business in new ways.
                </p>
                <p>Leveraging data can help a business increase revenue, improve customer satisfaction and also, make better decisions.</p>
                <p>iWebDesign can help you create automatic weekly/monthly reports and provide useful insights for your business. This can help you make business decisions with solid information on your hands.</p>

                </ScrollAnimation>
              </div>
            </div>
          </ScrollAnimation>

          <ScrollAnimation animateIn="fadeIn">
          <div class="row">
            <br/>  <br/>
            <div class="col-md-7">
              <ScrollAnimation animateIn="fadeInLeft">
              <img src={datainsights} alt="iWebDesign Auckland" className="animateImage"/>
              </ScrollAnimation>
            </div>
            <div class="col-md-5">
              <ScrollAnimation animateIn="fadeInBottom">
              <p>Depending on your requirements, we can develop:</p>
              <ul className="bullets">
                <li>Scheduled report delivery to your email (weekly, monthly, fortnightly, etc).</li><br/>
                <li>Self-service reports that can be run by you anytime to retrieve information.</li><br/>
                <li>Real time analytics.</li><br/>
                <li>Monitoring and alerts.</li>
              </ul>
              </ScrollAnimation>
              <br/>
              <ScrollAnimation animateIn='flipInX'>
              <Link to="/letstalk">

                  <span className="borderBtn"><strong> Let's Talk </strong>  <span className="dot">◉</span></span>

              </Link>
              </ScrollAnimation>
            </div>
          </div>
          </ScrollAnimation>


    </div>



        <Calltoactionsection />
        <Footer />
    </>
  );
}
