import React from 'react';
import './ContactSection.css';
import ContactUs from './ContactForm.js';
import ScrollAnimation from 'react-animate-on-scroll';

function ContactSection() {
  return (
    <div className='ContactSection'>
        <div class="row">
            <div class="col-md-6">
              <ScrollAnimation animateIn="fadeIn">
              <h1>If you have any questions or comments, please feel free to contact our friendly team.</h1>
              </ScrollAnimation>
            </div>
            <div class="col-md-6">
              <div className="contactDetails">
                <ScrollAnimation animateIn="fadeIn">
                <h3><i class="fa fa-phone"></i> Phone Number</h3>
                <p>(+64) 021 027 512 72</p>
                </ScrollAnimation>
              </div>
              <div className="contactDetails">
                <ScrollAnimation animateIn="fadeIn">
                <h3><i class="fa fa-envelope"></i> Email Address</h3>
                <p>contactiwebdesign@gmail.com</p>
                </ScrollAnimation>
              </div>
              <div className="contactDetails">
                <ScrollAnimation animateIn="fadeIn">
                <h3><i class="fas fa-map-marker-alt"></i> Auckland Studio</h3>
                <p>29 Dovey Place, Massey, Auckland 0612.</p>
                </ScrollAnimation>
              </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
              <ScrollAnimation animateIn="fadeInBottom">
                <ContactUs />
              </ScrollAnimation>
            </div>
        </div>
    </div>
  );
}

export default ContactSection;
