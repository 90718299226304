import React from 'react';
import '../../App.css';
import Footer from '../Footer';
import OurStoryPage from '../OurStoryPage';
import Servicesection from '../Servicesection';
import Calltoactionsection from '../Calltoactionsection';


export default function Ourstory() {



  return (
  <>
      <OurStoryPage />
      <Servicesection />
      <Calltoactionsection />
      <Footer />
    </>
)




}
