import React from 'react';
import '../../App.css';
import ScrollAnimation from 'react-animate-on-scroll';
import Calltoactionsection from '../Calltoactionsection';
import Footer from '../Footer';
import { Link } from 'react-router-dom';

import servicewebsitedesign from '../assets/images/portfolio/servicewebsitedesign.jpeg';

import mobilephone from '../assets/images/mobilePhone.png';

export default function Customwebsitedesigndevelopmentauckland() {
  return (
    <>
    <div className='iWebdesign-services'>
          <ScrollAnimation animateIn="fadeIn">
            <div class="row">
              <div class="col-md-12">

                <span>Services</span>
                <h1>Custom Website Design & Development.</h1>

                <hr/>
              </div>
            </div>
            <br /> <br />
            <div class="row">
              <div class="col-md-6">
              <ScrollAnimation animateIn="fadeInLeft">
              <h1>A beautiful application that keep your users engaged.</h1>
              <p>We design & develop websites and applications from scratch, which means that we will be using the latest technologies that work hard, achieve results, and generate a return on investment. For the last five years we have been providing clients with professional and cost effective website design & development services in Auckland.</p>
              <p>Our custom website designs and development services is guided by your business needs, offering only the web technology tools that we consider a best fit for your business requirements.</p>
              </ScrollAnimation><br/>
              <ScrollAnimation animateIn='flipInX'>
              <Link to="/Getfreequote">

                  <span className="borderBtn"><strong> Request a Free Quote </strong>  <span className="dot">◉</span></span>

              </Link>
              </ScrollAnimation>
              </div>
              <div class="col-md-6">

                    <img src={mobilephone} className="customMobileIcon" alt="iWebDesign Auckland"/>
              </div>
            </div>
          </ScrollAnimation>



    </div>



        <Calltoactionsection />
        <Footer />
    </>
  );
}
